import { ParsedNumber } from "@/types/peerview/peerview.types";

import { DECIMAL_SEP, MAX_DIGITS, ZERO_CHAR } from "./constants";

/**
 * Round the parsed number to the specified number of decimal places
 * This function changes the parsedNumber in-place
 */
export const roundNumber = (
  parsedNumber: ParsedNumber,
  minFrac: number,
  maxFrac: number,
) => {
  if (minFrac > maxFrac) {
    throw new Error(
      'The minimum number of digits after fraction (' +
        minFrac +
        ') is higher than the maximum (' +
        maxFrac +
        ').',
    );
  }

  let digits = parsedNumber.digits;
  let fractionLen = digits.length - parsedNumber.integerLen;
  const fractionSize = Math.min(Math.max(minFrac, fractionLen), maxFrac);

  // The index of the digit to where rounding is to occur
  let roundAt = fractionSize + parsedNumber.integerLen;

  const digit = digits[roundAt];

  if (roundAt > 0) {
    // Drop fractional digits beyond `roundAt`
    digits.splice(Math.max(parsedNumber.integerLen, roundAt));

    // Set non-fractional digits beyond `roundAt` to 0
    for (let j = roundAt; j < digits.length; j++) {
      digits[j] = 0;
    }
  } else {
    // We rounded to zero so reset the parsedNumber
    fractionLen = Math.max(0, fractionLen);
    parsedNumber.integerLen = 1;
    digits.length = Math.max(1, (roundAt = fractionSize + 1));
    digits[0] = 0;
    for (let i = 1; i < roundAt; i++) digits[i] = 0;
  }

  if (digit >= 5) {
    if (roundAt - 1 < 0) {
      for (let k = 0; k > roundAt; k--) {
        digits.unshift(0);
        parsedNumber.integerLen++;
      }
      digits.unshift(1);
      parsedNumber.integerLen++;
    } else {
      digits[roundAt - 1]++;
    }
  }

  // Pad out with zeros to get the required fraction length
  for (; fractionLen < Math.max(0, fractionSize); fractionLen++) digits.push(0);

  let dropTrailingZeros = fractionSize !== 0;

  // Minimal length = nb of decimals required + current nb of integers
  // Any number besides that is optional and can be removed if it's a trailing 0
  const minLen = minFrac + parsedNumber.integerLen;

  // Do any carrying, e.g. a digit was rounded up to 10
  const carry = digits.reduceRight(function (carry, d, i, digits) {
    d = d + carry;
    digits[i] = d < 10 ? d : d - 10; // d % 10
    if (dropTrailingZeros) {
      // Do not keep meaningless fractional trailing zeros (e.g. 15.52000 --> 15.52)
      if (digits[i] === 0 && i >= minLen) {
        digits.pop();
      } else {
        dropTrailingZeros = false;
      }
    }
    return d >= 10 ? 1 : 0; // Math.floor(d / 10);
  }, 0);

  if (carry) {
    digits.unshift(carry);
    parsedNumber.integerLen++;
  }
  digits = parsedNumber.digits;
  const integerLen = parsedNumber.integerLen;
  const exponent = parsedNumber.exponent;
  let decimals = [];

  // extract decimals digits
  if (integerLen > 0) {
    decimals = digits.splice(integerLen, digits.length);
  } else {
    decimals = digits;
    digits = [0];
  }

  // format the integer digits with grouping separators
  const groups = [];

  if (digits.length) {
    groups.unshift(digits.join(''));
  }

  let formattedText = groups.join('.');

  // append the decimal digits
  if (decimals.length) {
    formattedText += '.' + decimals.join('');
  }

  if (exponent) {
    formattedText += '.' + '+' + exponent;
  }

  return formattedText;
};

/**
 * Parses a number.
 * Significant bits of this parse algorithm came from https://github.com/MikeMcl/big.js/
 * Function borrowed from Angular 2's Decimal Pipe
 */
export const parseNumber = (num: number): ParsedNumber => {
  let numStr = Math.abs(num) + '';
  let exponent = 0;
  let digits, integerLen;
  let i, j, zeros;

  // Decimal point?
  if ((integerLen = numStr.indexOf(DECIMAL_SEP)) > -1) {
    numStr = numStr.replace(DECIMAL_SEP, '');
  }

  // Exponential form?
  if ((i = numStr.search(/e/i)) > 0) {
    // Work out the exponent.
    if (integerLen < 0) integerLen = i;
    integerLen += +numStr.slice(i + 1);
    numStr = numStr.substring(0, i);
  } else if (integerLen < 0) {
    // There was no decimal point or exponent so it is an integer.
    integerLen = numStr.length;
  }

  // Count the number of leading zeros.
  for (i = 0; numStr.charAt(i) === ZERO_CHAR; i++) {
    /* empty */
  }

  if (i === (zeros = numStr.length)) {
    // The digits are all zero.
    digits = [0];
    integerLen = 1;
  } else {
    // Count the number of trailing zeros
    zeros--;
    while (numStr.charAt(zeros) === ZERO_CHAR) zeros--;

    // Trailing zeros are insignificant so ignore them
    integerLen -= i;
    digits = [];
    // Convert string to array of digits without leading/trailing zeros.
    for (j = 0; i <= zeros; i++, j++) {
      digits[j] = Number(numStr.charAt(i));
    }
  }

  // If the number overflows the maximum allowed digits then use an exponent.
  if (integerLen > MAX_DIGITS) {
    digits = digits.splice(0, MAX_DIGITS - 1);
    exponent = integerLen - 1;
    integerLen = 1;
  }

  return { digits: digits, exponent: exponent, integerLen: integerLen };
};

export const getSplitNumber = (n: number) => {
  const nstring = n + '';
  const nindex = nstring.indexOf('.');
  const number = nindex > -1 ? nstring.substring(0, nindex) : '';
  const remainder = nindex > -1 ? nstring.substring(nindex + 1) : '';

  return {
    ratingWhole: number,
    ratingRemainder: remainder,
  };
};
