import { SVG } from '@svgdotjs/svg.js';

const RatingCircle = ({ rating, color }: { rating: number; color: string }) => {
  const svgCanvas = SVG();

  svgCanvas
    .circle(238)
    .fill('none')
    .stroke({ width: 12, color: '#cccccc' })
    .move(6, 6);

  const ratingDegrees = (360 / 5.0) * rating;
  const largeArc = ratingDegrees < 180 ? 0 : 1;
  const ratingRadians = ((ratingDegrees + 90) * 22.0) / 7.0 / 180;
  const origin = [126, 6];
  const center = [125, 125];

  const x = center[0] - 119 * Math.cos(ratingRadians);
  const y = center[1] - 119 * Math.sin(ratingRadians);

  svgCanvas
    .path(`M${x} ${y} A119 119 0 ${largeArc} 0 ${origin[0]} ${origin[1]}`)
    .fill('none')
    .stroke({ width: 12, color });

  svgCanvas.viewbox(0, 0, 250, 250);
  const svgString = svgCanvas.svg();

  svgCanvas.clear();
  return <span dangerouslySetInnerHTML={{ __html: svgString }}></span>;
};

export default RatingCircle;
