export class DateService {
  static getFullYear() {
    return new Date().getFullYear();
  }

  static isToday(day: number) {
    const currentDate = new Date();

    return currentDate.getDay() === day;
  }

  static isCurrentDay(day: string) {
    const weekday = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const currentDate = new Date();

    return weekday[currentDate.getDay()] === day;
  }

  static getCurrentTime() {
    return new Date().getHours();
  }

  static getDayOfWeek(item) {
    return item.operating_hours_id?.day_of_the_week.slice(0, 3);
  }

  static formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day} ${month}, ${year}`;
  }
}
