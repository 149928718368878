'use client';

import { Button } from '@chakra-ui/button';
import { Box, Divider, Flex, Heading } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { Tooltip } from '@chakra-ui/tooltip';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { getRating } from '@/services/peerview/peerview.service';
import {
  CustomerRating,
  RatingData,
  Score,
} from '@/types/peerview/peerview.types';

import { COLOR_PALETTE, PEERVIEW_LOGO } from './constants';
import CustomerRatingCard from './CustomerRatingCard';
import style from './Peerview.module.scss';
import RatingCircle from './RatingCircle';
import RatingStars from './RatingStars';
import RatingText from './RatingText';
import { parseNumber, roundNumber } from './utils';

type LinkProps = {
  reviewCount: number;
  onClick: (e: React.MouseEvent) => void;
};

type Props = {
  children: React.ReactNode;
  type: string;
  itemIdentifier: string;
  text?: string;
  canViewReviews?: boolean;
  LinkComponent: React.ComponentType<LinkProps>;
};

const Peerview = (props: Props) => {
  const {
    children,
    type,
    itemIdentifier,
    canViewReviews = true,
    LinkComponent,
  } = props;

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [data, setData] = useState<RatingData | null>(null);
  const [score, setScore] = useState<Score | null>(null);
  const {
    isOpen: isReviewModalOpen,
    onOpen: onReviewModalOpen,
    onClose: onReviewModalClose,
  } = useDisclosure();
  const [showBreakdownTooltip, setShowBreakdownTooltip] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const loadRating = async () => {
      try {
        const ratingData = await getRating(type, itemIdentifier);

        setIsDataLoaded(true);
        setData(ratingData.data);

        const rating = ratingData.data.rating || 0;
        const parsedRating = parseNumber(rating);

        setScore({
          score: roundNumber(parsedRating, 1, 1),
          ratings: ratingData.data.ratings.length,
          logoImage: PEERVIEW_LOGO,
        });
      } catch (e) {
        console.error(e);
      }
    };

    loadRating();
  }, []);

  const handleViewReviews = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onReviewModalOpen();
  };

  const peerviewScore = score?.score !== 'N/A' ? score?.score : 0;
  const ratingString = peerviewScore
    ? peerviewScore.toString().split('')
    : [''];

  return (
    <div className={style.wrapper}>
      {isDataLoaded ? (
        <>
          <RatingStars rating={parseFloat(score?.score || '0')} />
          <RatingText rating={ratingString} />
          <Image
            src={score!.logoImage}
            width="86"
            height="20"
            alt="peerview logo"
          />

          {children}

          {canViewReviews ? (
            <LinkComponent
              reviewCount={score?.ratings || 0}
              onClick={handleViewReviews}
            />
          ) : null}

          <Modal isOpen={isReviewModalOpen} onClose={onReviewModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Image
                  src={score!.logoImage}
                  width="240"
                  height="55"
                  alt="peerview logo"
                />
              </ModalHeader>
              <ModalCloseButton />

              <ModalBody>
                <Flex
                  direction={['column', 'row']}
                  flexWrap={'wrap'}
                  justifyContent={'center'}
                  gap={{ base: '20', md: '4' }}
                >
                  <Box
                    textAlign={'center'}
                    paddingY={['0px', '0px', '30px', '30px']}
                  >
                    <Heading as="h1" size="sm" noOfLines={1}>
                      {data?.ratingInfo}
                    </Heading>
                    <RatingStars rating={parseFloat(score?.score || '0')} />
                    <RatingText rating={ratingString} />
                  </Box>
                  <Box
                    className={style.ratingBreakdown}
                    textAlign={'center'}
                    paddingY={['0px', '0px', '30px', '30px']}
                    minWidth={'200px'}
                  >
                    {data?.ratingDetails &&
                      Object.keys(data?.ratingDetails).map(
                        (ratingLabel, index) => {
                          const rating = data?.ratingDetails[ratingLabel] || 0;
                          const parsed = parseNumber(rating);
                          const ratingStr = roundNumber(parsed, 1, 1)
                            .toString()
                            .split('');

                          return (
                            <Box
                              className={style.ratingContainer}
                              key={`rating-${index}`}
                            >
                              <Tooltip
                                hasArrow
                                isOpen={
                                  showBreakdownTooltip[ratingLabel] || false
                                }
                                label={ratingLabel}
                                aria-label={ratingLabel}
                              >
                                <Box
                                  onClick={() =>
                                    setShowBreakdownTooltip((oldValue) => {
                                      return {
                                        [ratingLabel]: !(
                                          oldValue[ratingLabel] || false
                                        ),
                                      };
                                    })
                                  }
                                >
                                  <RatingCircle
                                    rating={rating}
                                    color={
                                      COLOR_PALETTE[
                                        index % COLOR_PALETTE.length
                                      ]
                                    }
                                  />
                                  <RatingText rating={ratingStr} />
                                </Box>
                              </Tooltip>
                            </Box>
                          );
                        },
                      )}
                  </Box>
                  <Box
                    paddingTop={['0px', '0px', '10px', '10px']}
                    paddingBottom={['0px', '0px', '20px', '20px']}
                    className={style.customerReviewContainer}
                  >
                    {data?.ratings.map((customerRating: CustomerRating) => {
                      if (customerRating.status === 'Published') {
                        return (
                          <>
                            <CustomerRatingCard
                              key={customerRating.ratingId}
                              rating={customerRating}
                            />
                            <Divider orientation="horizontal" />
                          </>
                        );
                      }
                    })}
                  </Box>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onReviewModalClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      ) : null}
    </div>
  );
};

export default Peerview;
